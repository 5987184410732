import { ConfigProvider } from "antd";
import { ReactNode } from "react";

interface AntdConfigProps {
  children: ReactNode;
}

const AntdConfig = ({ children }: AntdConfigProps) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "IBM Plex Mono",
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};

export default AntdConfig;
