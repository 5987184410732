export const defaultTheme = {
  colors: {
    white: {
      100: "#fff",
      200: "#F2F2F2",
    },
    black: {
      100: "#000",
      200: "#181818",
      300: "#454545",
    },
    primary: {
      100: "#50209E",
      200: "#8236FF",
      300: "#B486FF",
      400: "#ece1ff",
    },
    secondary: {
      100: "#390046",
      200: "#71008D",
      300: "#CA33EF",
      400: "#EBB2F9",
    },
    tertiary: {
      100: "#242424",
      200: "#484848",
      300: "#939393",
      400: "#C9C9C9",
      500: "#E4E4E4",
    },
  },
  breakpoints: {
    xs: "650px",
    md: "1080px",
    xl: "1230px",
    xxl: "1270px",
  },
  sizes: {
    container: "1244px",
    title: {
      xl: "20px",
    },
    text: {
      xl: "16px",
      l: "14px",
      m: "13px",
      s: "12px",
      xs: "10px",
    },
    display: {
      xl: "18px",
      l: "17px",
      m: "16px",
      s: "14px",
      xs: "10px",
    },
    lineHeight: {
      l1: "16px",
      l2: "20px",
      l3: "24px",
    },
  },
  weights: {
    thin: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
  },
};
