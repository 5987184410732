"use client";

import StyledComponentsRegistry from "@/lib/registry";
import AntdConfig from "@/styles/antdTheme";
import { defaultTheme } from "@/styles/theme";
import { ThemeProvider } from "styled-components";

const Providers = (props: React.PropsWithChildren) => {
  return (
    <StyledComponentsRegistry>
      <ThemeProvider theme={defaultTheme}>
        <AntdConfig>{props.children}</AntdConfig>
      </ThemeProvider>
    </StyledComponentsRegistry>
  );
};

export default Providers;
